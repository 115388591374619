import React,{ useState, useEffect, } from "react";
import firebase from "firebase";
import { store } from "react-notifications-component";

export default function CommonSettings() {
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [serviceCharge, setServiceCharge] = useState("")
 
  useEffect(() => {
    setIsLayoutReady(true);
    loadExistingData();
    return () => setIsLayoutReady(false);
  }, []);

  const loadExistingData = async () => {
    firebase
      .database()
      .ref("serviceCharge")
      .once(`value`, (snap) => {
        const data = snap.val();
        setServiceCharge((data['value']||'').toString());
        
      });
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = () => {
    firebase
      .database()
      .ref(`serviceCharge`)
      .update({
        value: parseInt(serviceCharge),
      })
      .then(() => {
        store.addNotification({
          title: "Notification",
          message: "Service Charge Updated",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };
  

 
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="m-1 pt-5"> 
              <div style={{ display: "flex", flexDirection: "row" }}>
                 
                <div style={{ flex: 1 }}>
                  <label className="form-label">Set Service Charge</label>
                  <input
                    className="form-control"
                    placeholder="Service Charge"
                    value={serviceCharge}
                    onChange={(e) => {
                     let sc= e.target.value.replace(/[^0-9]/g, "");
                     setServiceCharge(sc);
                    }}
                  />

                </div>
              </div>
            </div>
 
            
            <div>
              <button
                disabled={isProcessing}
                type="submit"
                onClick={() => {
                  // console.log(JSON.stringify(aboutUsContent));
                  handleSubmit();
                }}
                className="btn btn-info  btn-sm"
              >
                Update Service Charge
              </button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
