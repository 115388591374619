import React,{ useState, useEffect, } from "react";
import firebase from "firebase";
import { store } from "react-notifications-component";
import imageCompression from "browser-image-compression";
import "./Styles.css";

export default function AboutUs() {
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const imagepath = "aboutus/images";

  useEffect(() => {
    setIsLayoutReady(true);
    loadExistingData();
    return () => setIsLayoutReady(false);
  }, []);

  const loadExistingData = async () => {
    firebase
      .database()
      .ref("pages/aboutus")
      .once(`value`, (snap) => {
        const data = snap.val();
        // console.log(JSON.stringify(data));
        setAboutUsContent({...data});
        // console.log(aboutUsContent);
        
        // editorInstanceRef.current.setData(data?.content || "");
      });
  };
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = () => {
    firebase
      .database()
      .ref(`pages/aboutus`)
      .update(aboutUsContent)
      .then(() => {
        store.addNotification({
          title: "Notification",
          message: "Page Updated",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };
  const [aboutUsContent, setAboutUsContent] = useState({
    banner: {
      image: "",
      content: "",
    },
    applinks: {
      ios: "",
      android: "",
    },
    whyus: [
      {
        image: "",
        content: "",
      },
      {
        image: "",
        content: "",
      },
      {
        image: "",
        content: "",
      },
      {
        image: "",
        content: "",
      },
    ],
    aboutus: {
      image: "",
      content: "",
    },
    vision: {
      image: "",
      content: "",
    },
    recognition: {
      image: "",
      content: "",
    },
    founder: {
      image: "",
      content: "",
    },
    contact: {
      name: "",
      adderess: "",
      email: "",
      phone: "",
    },
    social: {
      facebook: "",
      instagram: "",
      linkedin: "",
    },
  });

  const FilesUpload = async (event, jsonKey, callback) => {
    const imageFile = event.target.files[0];
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const url = URL.createObjectURL(compressedFile);
      await firebase
        .storage()
        .ref(`${imagepath}/${jsonKey}`)
        .put(compressedFile)
        .then(() => {
          firebase
            .storage()
            .ref(`${imagepath}/${jsonKey}`)
            .getDownloadURL()
            .then((url) => {
              console.log(url);
              callback(url);
              // aboutUsContent[jsonKey]["image"] = url;
              // setAboutUsContent({ ...aboutUsContent });
              // console.log(aboutUsContent);

              //  saveToDb(_categoryID, url)
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div className="m-1 pt-5">
              <h1>Banner Secetion </h1> 
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <img
                    onClick={() => {
                      document.getElementById(`bannerImage`).click();
                    }}
                    alt=""
                    style={{ width: 200, height: 100 }}
                    className="mt-3"
                    src={aboutUsContent["banner"]["image"] || ""}
                  />
                  <label htmlFor={`bannerImage`}>Set Banner Image</label>
                  <input className="d-none" id={`bannerImage`} 
                  onChange={(event) =>
                      FilesUpload(event, "bannerImage", (url) => {
                        aboutUsContent["banner"]["image"] = url;
                        setAboutUsContent({ ...aboutUsContent });
                      })
                    } type={"file"} />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Banner Content</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent["banner"]["content"]}
                    onChange={(e) => {
                      aboutUsContent["banner"]["content"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="m-1 pt-5">
              <h1>Why Us Section</h1>
              {aboutUsContent["whyus"].map((item, i) => (
                <div key={i} className="m-1">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 10,
                      }}
                    >
                      <img
                        onClick={() => {
                          document.getElementById(`whyusimage${i}`).click();
                        }}
                        alt=""
                        style={{ width: 200, height: 100 }}
                        className="mt-3"
                        src={item.image || ""}
                      />
                      <label htmlFor={`whyusimage${i}`}>Set Image</label>
                      <input
                        className="d-none"
                        id={`whyusimage${i}`}
                        type={"file"}
                        onChange={(event) =>
                          FilesUpload(event, `whyusimage${i}`, (url) => {
                            aboutUsContent["whyus"][i]["image"] = url;
                            setAboutUsContent({ ...aboutUsContent });
                          })
                        }
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label className="form-label">Content</label>
                      <textarea
                        required
                        className="form-control"
                        placeholder="Add Content"
                        value={item.content}
                        onChange={(e) => {
                          aboutUsContent["whyus"][i]["content"] =
                            e.target.value;
                          setAboutUsContent({ ...aboutUsContent });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="m-1 pt-5">
              <h1>About Us Section</h1>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Content</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent.aboutus.content}
                    onChange={(e) => {
                      aboutUsContent.aboutus.content = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="m-1 pt-5">
              <h1>Vision Section</h1>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Content</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent.vision.content}
                    onChange={(e) => {
                      aboutUsContent.vision.content = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="m-1 pt-5">
              <h1>Recognition Section</h1>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <img
                    onClick={() => {
                      document.getElementById(`recognitionImage`).click();
                    }}
                    alt=""
                    style={{ width: 200, height: 100 }}
                    className="mt-3"
                    src={aboutUsContent["recognition"]["image"] || ""}
                  />
                  <label htmlFor={`recognitionImage`}>
                    Set Recognition Image
                  </label>
                  <input
                    className="d-none"
                    id={`recognitionImage`}
                    type={"file"}
                    onChange={(event) =>
                      FilesUpload(event, "recognitionImage", (url) => {
                        aboutUsContent["recognition"]["image"] = url;
                        setAboutUsContent({ ...aboutUsContent });
                      })
                    }
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Recognition Content</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent["recognition"]["content"]}
                    onChange={(e) => {
                      aboutUsContent["recognition"]["content"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="m-1 pt-5">
              <h1>Founder Section</h1>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Content</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent.founder.content}
                    onChange={(e) => {
                      aboutUsContent.founder.content = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="m-1 pt-5">
              <h1>Contact Section</h1>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Name</label>
                  <input
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent["contact"]["name"]}
                    onChange={(e) => {
                      aboutUsContent["contact"]["name"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Email</label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent["contact"]["email"]}
                    onChange={(e) => {
                      aboutUsContent["contact"]["email"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Phone</label>
                  <input
                    required
                    type="tel"
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent["contact"]["phone"]}
                    onChange={(e) => {
                      aboutUsContent["contact"]["phone"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Address</label>
                  <textarea
                    required
                    className="form-control"
                    placeholder="Add Content"
                    value={aboutUsContent["contact"]["address"]}
                    onChange={(e) => {
                      aboutUsContent["contact"]["address"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h5 className="text-center">Social links</h5>
              <div className="d-flex justify-content-between">
                <div style={{ flex: 1 }}>
                  <label className="form-label">Facebook</label>
                  <input
                    required
                    type="url"
                    className="form-control"
                    placeholder="Add Link"
                    value={aboutUsContent["social"]["facebook"]}
                    onChange={(e) => {
                      aboutUsContent["social"]["facebook"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">Instagram</label>
                  <input
                    required
                    type="url"
                    className="form-control"
                    placeholder="Add Link"
                    value={aboutUsContent["social"]["instagram"]}
                    onChange={(e) => {
                      aboutUsContent["social"]["instagram"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label className="form-label">LinkedIn</label>
                  <input
                    required
                    type="url"
                    className="form-control"
                    placeholder="Add Link"
                    value={aboutUsContent["social"]["linkedin"]}
                    onChange={(e) => {
                      aboutUsContent["social"]["linkedin"] = e.target.value;
                      setAboutUsContent({ ...aboutUsContent });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                disabled={isProcessing}
                type="submit"
                onClick={() => {
                  // console.log(JSON.stringify(aboutUsContent));
                  handleSubmit();
                }}
                className="btn btn-info  btn-sm"
              >
                Update
              </button>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
