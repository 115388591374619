import React, {useEffect, useState} from "react";
import styled from "styled-components";
import firebase from "firebase";
import Modal from "react-modal";
import {store} from "react-notifications-component";
import {updateB2BUser} from "../../helpers/firebase/firestore";

const Container = styled.div``;

const UserComponent = ({
                           phone, uid, link, profileStatus,verifying, VerifyUser, userDetails, index, UpdateUserDetails, ...rest
                       }) => {
    const [shopImage, setShopImage] = useState({})
    const [modalOpen, setModalOpen] = useState(false);
    const [user,setUser]=useState(userDetails);
    function updateUser() {
        if (modalOpen) updateB2BUser(uid,{details:modalOpen})
            .then(() => {
                setUser(o=>({...o,details:modalOpen}))
                UpdateUserDetails(index, modalOpen)
                setModalOpen(false);
                store.addNotification({
                    title: "Notification",
                    message: "Status Updated",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000, onScreen: true,
                    },
                });
            });

    }

    useEffect(() => {
        firebase.storage().ref().child("images/" + uid).listAll().then((result) => {
            result.items.forEach((imageRef, index) => {
                imageRef.getDownloadURL().then(url => setShopImage(o => {
                    o[index] = url;
                    return {...o}
                }))
            });
        })
    }, [])
    return (<Container className="">
        <Modal isOpen={!!modalOpen} onRequestClose={() => {
            setModalOpen(false)
        }}>
            <div>
                <div className="w-100 d-flex justify-content-end">
                    <button onClick={() => setModalOpen(false)} className="btn btn-danger btn-sm">Close</button>
                </div>
                <div className="form-group">

                    <div className="form-label">Shop Name</div>
                    <input className="form-control" value={modalOpen.shopName}
                           onChange={e => setModalOpen({...modalOpen, shopName: e.target.value})}/>
                </div>
                <div className="form-group">

                    <div className="form-label">Owner Name</div>
                    <input className="form-control" value={modalOpen.ownerName}
                           onChange={e => setModalOpen({...modalOpen, ownerName: e.target.value})}/>
                </div>
                <div className="form-group">

                    <div className="form-label">Shop Address</div>
                    <input className="form-control" value={modalOpen.shopAddress}
                           onChange={e => setModalOpen({...modalOpen, shopAddress: e.target.value})}/>
                </div>
                <div className="form-group">

                    <div className="form-label">Location Link</div>
                    <input className="form-control" value={modalOpen.locationLink}
                           onChange={e => setModalOpen({...modalOpen, locationLink: e.target.value})}/>
                </div>
                <div className="form-group">

                    <div className="form-label">Business Type</div>
                    <input className="form-control" value={modalOpen.businessType}
                           onChange={e => setModalOpen({...modalOpen, businessType: e.target.value})}/>
                </div>
                <button className="btn btn-success" onClick={updateUser}>Update</button>

            </div>
        </Modal>
        <div className="mx-2 p-4">
            <div className=" is-4">
                {user.details && (
                    <p className="title is-6 has-text-weight-normal">Shop Name: {user.details.shopName}</p>)}
                {user.details && (<p className="title is-6 has-text-weight-normal">Owner
                    Name: {user.details.ownerName}</p>)}
                <p className="title is-6 has-text-weight-normal">User Id : {uid}</p>
                {user.details && (<p className="title is-6 has-text-weight-normal">Shop
                    Address: {user.details.shopAddress}</p>)}

                <p className="title is-6 has-text-weight-normal">Mobile: {phone}</p>
            </div>
            <div className="d-flex flex-wrap is-6 pb-5 has-text-left">
                <div className={'flex-wrap'} style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start'
                    }}>
                        <span>Shop Inner :</span>
                        <img style={{width: 300, height: 200}} src={shopImage[0]}/>
                    </div>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start'
                    }}>
                        <span>Shop Outer : </span>
                        <img style={{width: 300, height: 200}} src={shopImage[1]}/>
                    </div>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start'
                    }}>
                        <span>Shop Banner :</span>
                        <img style={{width: 300, height: 200}} src={shopImage[2]}/>
                    </div>
                </div>
            </div>
            {profileStatus !== true ? (<div className="column is-3">
                <button
                    disabled={verifying}
                    onClick={() => VerifyUser(index, true)}
                    className="button is-link"
                >
                    Verify{verifying&&"ing"} User
                </button>
            </div>) : (<div className="column is-3 my-3">
                <span className="title is-5">User Verified</span>{"   "}
                <div>
                    <button
                        onClick={() => VerifyUser(index, false)}
                        className="button is-danger">
                        Block User
                    </button>
                    <button
                        onClick={() => setModalOpen(user.details)}
                        className="button is-info">
                        Edit User
                    </button>
                </div>

            </div>)}
        </div>
    </Container>);
};
export default UserComponent;
