import React, { useState } from 'react'
import './delete.css'

import { store } from "react-notifications-component";


function DeletePage() {
    const [password, setPassword] = useState("")
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
 

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!password || !isConfirmed) return

    // Show a confirmation dialog
    const confirmDelete = window.confirm(
      "Are you absolutely sure you want to delete your account? This action cannot be undone.",
    )

    if (!confirmDelete) return

    setIsSubmitting(true)

    try {
      // Here you would make an API call to delete the account
      // For example:
      // await fetch('/api/account/delete', {
      //   method: 'DELETE',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ password })
      // })

      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000))
 store.addNotification({
          title: "Account deletion requested",
          message: "You will receive a confirmation sms shortly.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
       

        setPassword("")
        setIsConfirmed(false)
    } catch (error) {
        store.addNotification({
            title: "Error",
            message: "Failed to delete account. Please try again.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
    
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <div className="container">
    <h1>Delete Account Request Form</h1>
    
    <div className="warning-box">
        <p><strong>Warning:</strong> This action cannot be undone. Once you delete your account, all your data will be permanently removed from our system.</p>
    </div>
    
    <form id="delete-account-form" onSubmit={handleSubmit} >
        <div className="form-group">
            <label for="password">Enter your mobile number</label>
            <input type="text" id="password" name="password" value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required/>
        </div>
        
        <div className="checkbox-group">
            <label>
                <input type="checkbox" id="confirm-checkbox" 
                checked={isConfirmed}
                onChange={(checked) => setIsConfirmed(checked)}
                required />
                I understand that deleting my account will permanently remove all my data
            </label>
        </div>
        
        <div className="button-group">
            <button type="submit" className="btn-delete" id="delete-btn"
            disabled={!password || !isConfirmed || isSubmitting}
            >Delete Account</button>
        </div>
    </form>
</div>
  )
}

export default DeletePage