import React, {useState} from "react";
import styled from "styled-components";
import firebase from "firebase";
import axios from "axios";
import Modal from "react-modal";
import ReactLoading from "react-loading";

import {url} from "./../config/api";
import OrderCard from "./OrderDetails/OrderCard";
import SetDeliveryRate from "./OrderDetails/SetDeliveryRate";
import {MDBDataTableV5} from "mdbreact";
import {Button} from "react-bootstrap";

const Container = styled.div`
  padding-bottom: 20px;

  .svgDiv {
    div {
      display: block;
      margin: auto;
    }
  }
`;

const OrderDetails = () => {
  const [filter, _setFilter] = React.useState("");

  const [data, _setData] = React.useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [pendingModalOpen, setPendingModal] = React.useState(false);
  const [orderModal, setOrderModal] = React.useState(undefined);
  const [allDeliveryUsers, setAllDeliveryUsers] = React.useState([]);
  const [count, setCount] = React.useState({
    delivered: 0, pending: 0, cancelled: 0, processed: 0, dispatched: 0, all: 0
  });
  const dbpathDeliveryBoy = "customerapp/delivery/users";

  function setPendingModalOpen() {
    setPendingModal(true);
  }

  function closePendingModalOpen() {
    setPendingModal(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const addDeliveryBoy = (delivertBoyID, key, phone) => {
    if (delivertBoyID.toString().trim().length > 0) {
      firebase.database()
        .ref(`order/${phone}/${key}`)
        .update({"deliveryBoyIDs": delivertBoyID})
        .then(() => {
          alert("Delevery boy assigned")
          OrderFetch();
        });
    } else {
      alert("Please select one delevery boy to assign")
    }
  }
  const [orderSummary, _setOrderSummary] = React.useState([]);
  const [pendingOrderSummary, _setPendingOrderSummary] = React.useState([]);
  React.useEffect(() => {

    let orderData = [];
    const pendingData = {};
    const SummaryData = {};
    setCount({
      delivered: 0, pending: 0, cancelled: 0, processed: 0, dispatched: 0, all: 0
    })
    data.map((item) => {
      let toPush = []
      Object.keys(item.orderData).map(k => {
        toPush.push({...item.orderData[k], accessKey: k})
      })
      toPush = toPush.map(o => {
        const v = {...o}
        if (!v.pending) v.pending = 0;
        v.uid = v.accessKey;
        switch (v.status) {
          case "delievered": {
            setCount(old => ({...old, delivered: old.delivered + 1, all: old.all + 1}))
            break;
          }
          case "cancelled": {
            setCount(old => ({...old, cancelled: old.cancelled + 1, all: old.all + 1}))

            break;
          }
          case "dispatched": {
            setCount(old => ({...old, dispatched: old.dispatched + 1, all: old.all + 1}))
            break;
          }
          case "pending": {
            setCount(old => ({...old, pending: old.pending + 1, all: old.all + 1}))
            break;
          }
          case "processed": {
            setCount(old => ({...old, processed: old.processed + 1, all: old.all + 1}))
            break;
          }
          default: {
            setCount(old => ({...old, all: old.all + 1}))

          }
        }
        if (v.date) {
          let arr = v.date.split('/');
          if (parseInt(arr[2]) <= 9) arr[2] = '0' + arr[2];
          if (parseInt(arr[1]) <= 9) arr[1] = '0' + arr[1];
          v.date = arr.join('/');
        }
        if (v.time) {
          let arr = v.time.split(':');
          if (parseInt(arr[0]) <= 9) arr[0] = '0' + arr[0];
          if (parseInt(arr[1]) <= 9) arr[1] = '0' + arr[1];
          v.time = arr.join(':');
        } else v.time = "00:00"
        v.statusName = v.status;
        if (v.statusName === "delievered") {
          v.statusName = "Accepted"
        }

        if (item.shopName.toString().length > 0) {
          v.shopName = item.shopName
        }
        if (!v.shopName) {
          if (v.houseNo) {
            v.shopName = v.houseNo
          } else v.invalid = true;
        }
        if (['', '.', '..'].includes(v.shopName)) v.invalid = true;
        if (!v.address) {
          if (v.sector) {
            v.address = v.sector;
          }
        }
        if (v.accessKey === '-N0PuJwCc2fo3PEPmfwk') console.log(v)

        v.details = <button className="button" onClick={() => {
          setOrderModal(v);
        }}>Details</button>
        v.assign = <select className='form-control'
                           value={v.deliveryBoyIDs} onChange={(e) => {
          addDeliveryBoy(e.target.value, v.accessKey, v.phone)
        }}>
          <option value={""}>Not Assigned</option>
          {Object.keys(allDeliveryUsers).map((duid) => {

            return <option key={Math.random()}
                           value={allDeliveryUsers[duid].userid}>{allDeliveryUsers[duid].name}</option>;
          })}
        </select>
        return v
      })
      toPush = toPush.filter(e => !e.invalid)
      orderData.push(...toPush);
    });
    orderData.map((item) => {
      if (item.status === "processed") {
        if (item.product) {
          item.product.map((data) => {
            const name=data.itemDatabase||data.productName
            if (SummaryData.hasOwnProperty(name)) {
              SummaryData[name] = {
                ...SummaryData[name],
                quantity: SummaryData[name].quantity + data.quantity.quantity,
              };
            } else {
              SummaryData[name] = {
                quantity: data.quantity.quantity, prefix: data.prefix, name,
              };
            }
          });
        }
      }
      if (item.status === "pending") {
        if (item.product) {
          item.product.map((data) => {
            if (pendingData.hasOwnProperty(data.itemDatabase)) {
              pendingData[data.itemDatabase] = {
                ...pendingData[data.itemDatabase],
                quantity: pendingData[data.itemDatabase].quantity + data.quantity.quantity,
              };
            } else {
              pendingData[data.itemDatabase] = {
                quantity: data.quantity.quantity, prefix: data.prefix, name: data.itemDatabase,
              };
            }
          });
        }
      }
    });

    if (filter !== '') orderData = orderData.filter(x => x.status === filter)
    orderData = orderData.sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      if (a.time > b.time) return -1;
      if (a.time < b.time) return 1;
      return 0;
    })
    setOrdersDatatable(old => ({...old, rows: orderData}))

    if (pendingData.length != 0) {
      _setPendingOrderSummary(Object.values(pendingData));
    }
    if (SummaryData.length != 0) {
      _setOrderSummary(Object.values(SummaryData));
    }
  }, [data, filter]);

  const loadAllDeliveryBoys = async () => {
    firebase.database()
      .ref(dbpathDeliveryBoy)
      .once('value')
      .then(snapshot => {
        let data = snapshot.val();
        if (data) {
          let dbs = [];
          Object.keys(data).map((dbid) => {
            if (data[dbid].isProfileComplete && !data[dbid].isBlocked) {
              dbs.push({...data[dbid], fbid: dbid})
            }
          });
          setAllDeliveryUsers(dbs)
        }
      });

  }

  const OrderFetch = async () => {
    const token = await firebase.auth().currentUser.getIdToken();
    firebase
      .database()
      .ref(`order`)
      .once(`value`, (snap) => {
        const data = [];
        const uids = [];
        snap.forEach((item) => {
          uids.push(Object.values(item.val())[0].uid);
          data.push({phone: item.key, orderData: {...item.val()}});
        });
        axios
          .post(`${url}/fetchshopname`, uids, {
            headers: {Authorization: `Bearer ${token}`},
          })
          .then((res) => {
            res.data.map((item, index) => {
              data[index].shopName = item.shopName;
            });
            _setData([...data]);
          });
      });

  };

  React.useEffect(() => {
    loadAllDeliveryBoys()
    OrderFetch();
  }, []);

  const [OrdersDatatable, setOrdersDatatable] = useState({
    columns: [{
      label: 'Order ID', field: 'uid', width: 300, attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Category',
      },
    }, {
      label: 'Shop Name', field: 'shopName', width: 200, attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Shop Name',
      },
    }, {
      label: 'Address', field: 'address', width: 300, attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Address',
      },
    }, {
      label: 'Date|Time', field: 'date', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Date|Time',
      },
    }, {
      label: 'Phone', field: 'phone', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Phone',
      },
    }, {
      label: 'Status', field: 'statusName', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Action',
      },
    }, {
      label: 'Total', field: 'price', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Total',
      },
    }, {
      label: 'Pending', field: 'pending', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Pending',
      },
    }, {
      label: 'Assign', field: 'assign', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Assign',
      },
    }, {
      label: 'Details', field: 'details', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Total',
      },
    },], rows: []
  });

  function printDiv() {
    // console.log(itemArray);
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');


    mywindow.document.write(`<!DOCTYPE html>
     <html>
       <head>
         <link rel="preconnect" href="https://fonts.gstatic.com" />
         <link
           href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
           rel="stylesheet"
         />
         <script>
         window.addEventListener('DOMContentLoaded', (event) => {
           
            setTimeout(() => {
              window.print();
              window.close();              
            }, 2000);
        });
         </script>
       </head>
       <body>
       <div class="">
                        <div class="">
                            <h3 class="">Order Summary</h3>
                        </div>
                        ${orderSummary.map((item) => (`<div style="display: flex">
                                <div style="margin-right: 10px">${item.name}:</div>
                                <div style="margin-bottom: 20px">
                                    ${item.quantity}
                                    ${item.prefix}
                                </div>
                            </div>`)).join('')}
                    </div>
       </body>
     </html>`)
    //  mywindow.document.write(<invoicePdfGenerator shopName={shopName} phoneNumber={phoneNumber} date={} product={itemArray.product} price={} delieveryPrice={} />);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();

    return true;
  }

  return (<Container>
    <Modal isOpen={orderModal !== undefined} onRequestClose={() => setOrderModal(undefined)}>
      <Button variant="danger" onClick={() => setOrderModal(undefined)}>X</Button>
      {orderModal && <OrderCard key={orderModal.accessKey}
                                OrderFetch={OrderFetch}
                                DeliveryBoy={allDeliveryUsers}
                                filter={filter}
                                phone={orderModal.phone}
                                orderData={(() => {
                                  const x = {}
                                  x[orderModal.accessKey] = orderModal
                                  return x;
                                })()}/>}
    </Modal>
    <h3 className="title is-3 has-text-weight-normal has-text-black">
      Order Details
    </h3>
    <h3 className="m-2 font-weight-bold">Filter</h3>

    <div className="d-flex">
      <button
        onClick={() => {
          _setFilter("");
        }}
        className={`button my-2 mx-2`}
      >
        Show All ({count.all})
      </button>
      <button
        onClick={() => {
          if (filter === "delievered") {
            _setFilter("");
          } else {
            _setFilter("delievered");
          }
        }}
        className={`button ${filter === "delievered" && "is-danger"} my-2 mx-2`}
      >
        Show Delievered Only ({count.delivered})
      </button>
      <button
        onClick={() => {
          if (filter === "pending") {
            _setFilter("");
          } else {
            _setFilter("pending");
          }
        }}
        className={`button ${filter === "pending" ? "is-danger" : ""} my-2 mx-2`}
      >
        Show Pending Only ({count.pending})
      </button>
      <button
        onClick={() => {
          if (filter === "cancelled") {
            _setFilter("");
          } else {
            _setFilter("cancelled");
          }
        }}
        className={`button ${filter === "cancelled" ? "is-danger" : ""} my-2 mx-2`}
      >
        Show Cancelled Only ({count.cancelled})
      </button>
      <button
        onClick={() => {
          if (filter === "processed") {
            _setFilter("");
          } else {
            _setFilter("processed");
          }
        }}
        className={`button ${filter === "processed" ? "is-danger" : ""} my-2 mx-2`}
      >
        Show Processed Only ({count.processed})
      </button>
      <button
        onClick={() => {
          if (filter === "dispatched") {
            _setFilter("");
          } else {
            _setFilter("dispatched");
          }
        }}
        className={`button ${filter === "dispatched" ? "is-danger" : ""} my-2 mx-2`}
      >
        Show Dispatched Only ({count.dispatched})
      </button>

    </div>
    {filter === "processed" && (<div className="mb-5">
      <button onClick={openModal} className="button is-dark">
        Show Order Summary
      </button>
    </div>)}
    {filter === "pending" && (<div className="mb-5">
      <button onClick={setPendingModalOpen} className="button is-dark">
        Show Pending Order Summary
      </button>
    </div>)}
    <MDBDataTableV5
      scrollX hover entriesOptions={[5, 20, 25, 50, 100]}
      entries={20} pagesAmount={4} data={OrdersDatatable}/>

    <div className=" mt-6">
      {data.length === 0 && (<div className="svgDiv">
        <ReactLoading type={"spin"} color="#000"/>
      </div>)}
      {/*{data.map((item) => (*/}
      {/*  <OrderCard*/}
      {/*    key={item.phone}*/}
      {/*    OrderFetch={OrderFetch}*/}
      {/*    DeliveryBoy={allDeliveryUsers}*/}
      {/*    filter={filter}*/}
      {/*    {...item}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      <div>
        <button className="button is-success" onClick={()=>{
          const names=['Order Name, Order Quantity'];
          orderSummary.forEach(item=>{
            names.push(item.name+', '+item.quantity+item.prefix)
          })
          const csvContent = "data:text/csv;charset=utf-8,"
              + names.join("\n");
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `order_summary_${new Date().toLocaleString()}.xlsx`);
          document.body.appendChild(link); // Required for FF

          link.click();
        }}>
          Download
        </button>
        <button className="button is-danger" onClick={closeModal}>
          Close
        </button>
        <div className="mt-3">
          <div className="column is-12">
            <h3 className="title is-3 has-text-black">Order Summary</h3>
          </div>
          {orderSummary.map((item) => (<div className={'d-flex justify-content-between my-3 px-5'} key={item.name}>
            <div className="">{item.name}</div>
            <div className=" ">
              {item.quantity}
              {item.prefix}
            </div>
          </div>))}
        </div>
      </div>
    </Modal>
    <Modal
      isOpen={pendingModalOpen}
      onRequestClose={closePendingModalOpen}
      contentLabel="Example Modal"
    >
      <div>
        <button className="button is-danger" onClick={closePendingModalOpen}>
          Close
        </button>
        <div className="columns is-multiline is-mobile mt-3">
          <div className="column is-12">
            <h3 className="title is-3 has-text-black">Order Summary</h3>
          </div>
          {pendingOrderSummary.map((item) => (<React.Fragment key={item.name}>
            <div className="column is-4">{item.name}</div>
            <div className="column is-8">
              {item.quantity}
              {item.prefix}
            </div>
          </React.Fragment>))}
        </div>
      </div>
    </Modal>
  </Container>);
};
export default OrderDetails;
