import React, {useEffect, useState} from "react";
import styled from "styled-components";
import firebase from "firebase";
import {store} from "react-notifications-component";
const Container = styled.div`
  img {
    border-radius: 5px;
  }
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 10px;
`;
// url,
// miniDescription,
// price,
// quantity,
// productName,
const ShowProductsOrdered = ({
  orderData,
  accessKey,
  ChangeStatus,
  shopName,
    phone,
  filter,
  DeliveryBoy,
  addDeliveryBoy
}) => {
  const [pending,setPending]=useState(orderData[accessKey].pending?orderData[accessKey].pending:0);
  const [collected,setCollected]=useState(0);
  const [productsOrderedShow, _setProductsOrderedShow] = React.useState(false);
  const [delivertBoyID, setDelivertBoyID] = React.useState(orderData[accessKey].deliveryBoyIDs||"")
  const ref = React.useRef(null);

  const ProductsOrderedShownToggle = () =>
    _setProductsOrderedShow(!productsOrderedShow);

  const [dateOfOrder, _setDateOfOrder] = React.useState("");

  React.useEffect(() => {
    if (orderData[accessKey].hasOwnProperty("date")) {
      const ArrayOfDate = orderData[accessKey].date.split("/");
      const date = `${ArrayOfDate[2]}/${ArrayOfDate[1]}/${ArrayOfDate[0]}`;
      _setDateOfOrder(date);
    }
  }, [orderData]);
  React.useEffect(() => {
    var _= orderData[accessKey];
  }, [])
  const getPrice = (props) => {
    let price = 0;
    if(props.delieveryPrice){
      price = props.price + props.delieveryPrice
    }
    if(props.serviceCharge){
      price = price + props.serviceCharge
    }
    return price;
  };
  function printDiv(shopName, phoneNumber, invoiceDate,itemArray) {
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');


     mywindow.document.write(`<!DOCTYPE html>
     <html>
       <head>
         <link rel="preconnect" href="https://fonts.gstatic.com" />
         <link
           href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
           rel="stylesheet"
         />
         <style>
           h1,
           p {
             margin-block-end: 0em;
             margin-block-start: 0em;
             font-family: "Poppins", sans-serif;
           }
           h2 {
             margin-block-end: 0em;
             margin-block-start: 0em;
             font-weight: 300;
             font-family: "Poppins", sans-serif;
           }
           h3 {
             margin-block-end: 0em;
             margin-block-start: 0em;
             font-weight: 400;
             font-family: "Poppins", sans-serif;
           }
           h4,
           h5 {
             margin-block-end: 0em;
             margin-block-start: 0em;
             font-weight: 400;
             font-family: "Poppins", sans-serif;
           }
           .heading {
             font-size: 25px;
             margin-bottom: 20px;
             background-color: #33cc00;
             padding: 5px;
             color: #fff;
           }
           .flex-container {
             flex-direction: row;
             display: flex;
             width: 90%;
             padding-left: 2vh;
             gap:10px;
             flex-grow: 1;
             justify-content: space-between;
           }
           
           .flex-container2 {
             flex-direction: row;
             display: flex;
             background-color: #f5f5f5;
             width: 95vw;
             margin-top: 30px;
             height: 40px;
           }
           .item1 {
             width: 40vw;
             padding-left: 2vw;
             transform: translateY(8px);
           }
           .item2 {
             width: 20vw;
             padding-left: 2vw;
             transform: translateY(8px);
           }
           .item3 {
             width: 10vw;
             padding-left: 2vw;
             transform: translateY(8px);
           }
           .item4 {
             width: 10vw;
             padding-left: 2vw;
             transform: translateY(8px);
           }
           .item5 {
             width: 10vw;
             padding-left: 2vw;
             transform: translateY(8px);
           }
           .flex-container3 {
             flex-direction: row;
             display: flex;
             border: 1px solid #f5f5f5;
             border-left-width: 1px;
             border-right-width: 1px;
             width: 95vw;
             height: 40px;
           }
           .product_image {
            width: 58px;
            border: 1px solid;
            height: 39px;
            background-size: contain;
          }
           .product_image_container{
            width:50px;
          }
           .padd-left {
             padding-left: 60vw;
             padding-top: 10vh;
           }
           .boldText {
             font-weight: bold;
           }
           @media print {
            body {-webkit-print-color-adjust: exact;}
            }
            
         </style>
         <script>
         window.addEventListener('DOMContentLoaded', (event) => {
           
            setTimeout(() => {
              window.print();
              window.close();              
            }, 5000);
        });
         </script>
       </head>
       <body>
         <div style="width: 100%; align-self: center">
           <div style="text-align: flex-start">
             <img
               style="height: 90px; width: 200px; object-fit: contain"
               src='https://firebasestorage.googleapis.com/v0/b/freshtables-5ca16.appspot.com/o/ft.png?alt=media&token=f73f7634-53bf-45aa-aeba-7fc2acb14814'
             />
             <div style="text-align: center">
               <h3 class="heading">Invoice</h3>
             </div>
             <div class="flex-container">
               <div style="width: 60%;">
                <h2>
                <b>FRESHABLES PRIVATE LIMITED</b></h2>

<h4>                <br/>
                Hyd-501504
                <br/>
                +91 9110514772
                <br/>
                GSTIN: 36AAECF1811J1ZF
                <br/>
                CIN: U01100TG2021PTC14980
</h4>
                <br/>
                <br/>

                 <h2><b>Shop Name</b>: ${shopName}</h2>
                 <br/>
                 <h3><b>Phone Number</b> : ${phoneNumber}</h3>
                
                 
                 <h3><b>Delivery Location</b> : ${itemArray.address||itemArray.houseNo}</h3>
               </div>
               <div>
                 <h4><b>Id : ${itemArray.orderId}</b></h4>
                 <br/>
                 <h4><b>Invoice Date </b>: ${invoiceDate}</h4>
                 <h4><b>Payment Mode </b>: Cash</h4>
                 <br/>
                 <h4><b>Slot</b>:${itemArray.shippingSlot}</h4>
                 <h4><b>No. of Items</b>:${itemArray.product.length}</h4>
                 <br/>
                 
                 <h4><b>Subtotal</b>: Rs. ${itemArray.price}</h4>
                 ${itemArray.serviceChargePercentage>0?'<h4><b>Service Charge ('+itemArray.serviceChargePercentage+'%)</b>: Rs.'+itemArray.serviceCharge+'</h4>':''}
                 <h4><b>Delivery</b>: Rs. ${itemArray.delieveryPrice||0}</h4>
                 <h3><b>Total</b>: Rs. ${getPrice(itemArray)}</h3>
               </div>
             </div>
             
             <div class="flex-container2">
             <div class="product_image_container">
                 <p>&nbsp;</p>
               </div>
               <div class="item1">
                 <p>Items</p>
               </div>
               <div class="item2">
                 <p>Quantity</p>
               </div>
               <div class="item3">
                 <p>Price</p>
               </div>
               <div class="item4">
                 <p>Total</p>
               </div>
             </div>
             ${itemArray.product.map(
               (item2) =>{
                 return (
                   `<div class="flex-container3">
                    <div class="product_image_container">
                        <img class="product_image" src='${item2.url}' alt="" />
                     </div>
                     <div class="item1">
                        <p>${item2.productName} </p>
                     </div>
                     
                     <div class="item2">
                     <p>${item2.quantity.quantity}</p>
                     </div>
                     <div class="item3">
                     <p>${item2.price}</p>
                     </div>
                     <div class="item4">
                     <p>${item2.quantity.quantity * item2.price}</p>
                     </div>
                 </div>`
                 )
               }
                 
             )}
             <div class="padd-left">
             <p><span class="boldText">Subtotal : </span>Rs. ${itemArray.price}</p>
             ${
              itemArray.serviceChargePercentage
                ? `<p><span class="boldText">Service Charge(${itemArray.serviceChargePercentage}%) : </span>Rs. ${itemArray.serviceCharge} </p>`
                : ``
            }
              ${
                itemArray.delieveryPrice
                  ? `<p><span class="boldText">Delivery : </span>Rs. ${itemArray.delieveryPrice} </p>`
                  : ``
              }
               <p><span class="boldText">Total : </span>Rs. ${
                 getPrice(itemArray)
               }</p>
             </div>
             <div style="margin: 25px 20px 10px">
             <h4 style="font-weight: bold">Terms & Conditions</h4>
             <ul>
             <li>You can either make an online payment or pay cash at the time of delivery</li>
             <li>Any damaged items can be returned at the time of delivery</li>
             <li>Returns are not accepted once delivery is confirmed and acknowledged</li>
</ul>
</div>
           </div>
         </div>
       </body>
     </html>`)
    //  mywindow.document.write(<invoicePdfGenerator shopName={shopName} phoneNumber={phoneNumber} date={} product={itemArray.product} price={} delieveryPrice={} />);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();

    return true;
  }
  function time(ms, shopName='') {
    if (ms != undefined) {
      if (ms.toString().length > 0) {
        try {
          var d = new Date(ms);
          var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
          return datestring;
        } catch (e) {
          return "Time Not available";
        }

      } else {
        return "Time Not available";
      }
    } else {
      return "Time Not available";
    }

  }

  const printBill = async () => { };
  useEffect(()=>{
    const deliveryBoy=DeliveryBoy.find((d=>d.userid===delivertBoyID));
    setCollected(getCollected(accessKey,deliveryBoy))
  },[delivertBoyID])
  function getCollected(orderID,deliveryBoy){
    if(deliveryBoy){
      if(deliveryBoy.money){
        if(deliveryBoy.money[orderID]){
          return deliveryBoy.money[orderID].collected;
        }
      }
    }
    return 0;
  }
  async function updatePendingCollected(){
    const deliveryBoy=DeliveryBoy.findIndex((d=>d.userid===delivertBoyID));
    await firebase.database()
        .ref(`order/${phone}/${accessKey}`)
        .update({pending})

    if(deliveryBoy!==-1) {

      if(!DeliveryBoy[deliveryBoy].money)DeliveryBoy[deliveryBoy].money={};
      DeliveryBoy[deliveryBoy].money[orderData[accessKey].orderid]={...deliveryBoy.money,collected};
      firebase.database()
          .ref(`customerapp/delivery/users/${DeliveryBoy[deliveryBoy].fbid}/money/${accessKey}`)
          .update({collected,type:"b2b"})
          .then(() => {
            store.addNotification({
              title: "Notification",
              message: "Status Updated",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          });
    }

  }

  if(filter!==""&&filter!==orderData[accessKey].status) return null;
  return <Container key="aaah" ref={ref} className="column is-12">
    <div style={{
      justifyContent:"start",
      alignItems:"start"
    }} className="columns is-multiline my-1 is-vcentered">
      <div style={{textAlign:"left"}} className="column is-3">
        <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black">
          Shop Name : {shopName}
          <br />
          Phone Number : {orderData[accessKey].phone}
          <br />
        </h3>
      </div>
      <div style={{textAlign:"left"}} className="column is-4">
        <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black mb-2">
          Order Made On(Date) : {dateOfOrder}
        </h3>
        <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black">
          Order Made On(Time) :{" "}
          {time(orderData[accessKey].milliseconds,shopName)}
          { }
        </h3>
        <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black mb-2">
          Expected Delivery : {orderData[accessKey].shippingSlot||""}
        </h3>
      </div>
      <div className="column is-2">
        <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black">
          {orderData[accessKey].status?.toUpperCase()}{" "}
        </h3>
      </div>
      <div style={{textAlign:"left"}} className="column is-3">
        <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black">
          Order Total : {orderData[accessKey].price}{" "}
        </h3>

        {orderData[accessKey].serviceChargePercentage ? (
            <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black">
              Service Charge({orderData[accessKey].serviceChargePercentage} %) :{orderData[accessKey].serviceCharge}{" "}
            </h3>
        ) : null}

        {orderData[accessKey].delieveryPrice ? (
            <h3 style={{ margin:0 }} className="subtitle is-6 has-text-black">
              Delievery Rate :{orderData[accessKey].delieveryPrice}{" "}
            </h3>
        ) : null} 
        
        <h3 style={{ margin:0 }}>Total: {getPrice(orderData[accessKey])}</h3>
      </div>

      <div className="column is-8">
        <h3 className="subtitle is-6 has-text-black">
          Address:{" "}
          {orderData[accessKey].address
              ? orderData[accessKey].address
              : orderData[accessKey].houseNo}{" "}
        </h3>
      </div>
      <div className="column is-4">
        {" "}
        <h3 className="subtitle is-6 has-text-black">
          Order ID :{" "}
          {orderData[accessKey].orderId
              ? orderData[accessKey].orderId
              : "Order Id Not Available"}
        </h3>
      </div>
      <div className="column is-4">
        <button
            onClick={() => {
              printDiv(shopName,orderData[accessKey].phone,dateOfOrder,orderData[accessKey])
            }}
            className="button"
        >
          Download PDF
        </button>
      </div>
      <div className="column is-4">
        <select  className='form-control'
                 value={delivertBoyID} onChange={(e) => { setDelivertBoyID(e.target.value); }} >
          <option value={""}>Not Assigned</option>
          {
            Object.keys(DeliveryBoy).map((duid)=>{

              return <option key={Math.random()} value={DeliveryBoy[duid].userid}>{DeliveryBoy[duid].name}</option>;
            })
          }
        </select>
        <button
            onClick={() => {
              addDeliveryBoy(delivertBoyID,accessKey);
            }}
            className="button"
        >
          Assign DeliveryBoy
        </button>
      </div>
      <div className="column is-4 has-text-right">
        {orderData[accessKey].status === "pending" && (
            <button
                onClick={() => {
                  ChangeStatus("cancelled", accessKey);
                }}
                className="button is-danger"
            >
              Cancel Order
            </button>
        )}
        {orderData[accessKey].status === "processed" && (
            <button
                onClick={() => {
                  ChangeStatus("cancelled", accessKey);
                }}
                className="button is-danger"
            >
              Cancel Order
            </button>
        )}
      </div>
      <div className="column is-12">
        {orderData[accessKey].status === "cancelled" ? (
            <h3>Order Canceled</h3>
        ) : orderData[accessKey].status === "delievered" ? (
            <h3>Order Delievered</h3>
        ) : (
            <>
              <button
                  className="button is-link"
                  style={{
                    width: "100%",
                    opacity: orderData[accessKey].status === "pending" ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (orderData[accessKey].status === "pending") {
                      ChangeStatus("processed", accessKey);
                    } else {
                      alert("action disabled");
                    }
                  }}
              >
                Order In Processing
              </button>
              <button
                  className="button is-link mt-4"
                  style={{
                    width: "100%",
                    opacity:
                        orderData[accessKey].status === "processed" ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (orderData[accessKey].status === "processed") {
                      ChangeStatus("dispatched", accessKey);
                    } else {
                      alert("action disabled");
                    }
                  }}
              >
                Order Dispatched
              </button>
              <button
                  className="button is-link mt-4"
                  style={{
                    width: "100%",
                    opacity:
                        orderData[accessKey].status === "dispatched" ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (orderData[accessKey].status === "dispatched") {
                      ChangeStatus("delievered", accessKey);
                    } else {
                      alert("action disabled");
                    }
                  }}
              >
                Order Delievered
              </button>
            </>
        )}
      </div>
      <div className="container">
        <div className="form-group">
          <div className="form-label">Pending Money</div>
          <input key="12"  value={pending} onChange={e=>setPending(parseInt(e.target.value))} type="number" className="form-control" placeholder="Pending Money"/>
        </div>
        <div className="form-group">
          <div className="form-label">Money Collected</div>
          <input value={collected} disabled={delivertBoyID===""} onChange={e=>setCollected(parseInt(e.target.value))} type="number" className="form-control" placeholder="Money Collected"/>
        </div>
        <button disabled={delivertBoyID===""} onClick={updatePendingCollected} className="btn btn-info">Update</button>
      </div>
      <div className="column is-12">
        <button
            onClick={ProductsOrderedShownToggle}
            className="button is-link"
            style={{ width: "100%" }}
        >
          {productsOrderedShow ? "Hide" : "Show"} Products Ordered
        </button>
      </div>
      {productsOrderedShow && (
          <div ref={ref} className="column is-12">
            <div className="columns is-multiline">
              <div className="column is-8">
                <h3>Products Ordered</h3>
              </div>
              <div className="column is-2">
                <h3>Quantity</h3>
              </div>
              <div className="column is-2">
                <h3>Price</h3>
              </div>
            </div>
            <div className="columns is-multiline">
              {orderData[accessKey].product.map((item) => (
                  <div className="column is-12 ">
                    <div className="columns is-multiline is-vcentered">
                      <div className="column is-1">
                        {" "}
                        <img src={item.url} />
                      </div>
                      <div className="column is-7">
                        {" "}
                        <h3 className="title is-6 has-text-black my-0">
                          {item.productName}
                        </h3>
                      </div>
                      <div className="column is-2">
                        <h3 className="subtitle is-6 has-text-black my-0">
                          {item.quantity.label}
                        </h3>
                      </div>
                      <div className="column is-2">
                        <h3 className="subtitle is-6 has-text-black my-1">
                          Rs {item.price * item.quantity.quantity}
                        </h3>
                      </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
      )}
    </div>
  </Container>
};
export default ShowProductsOrdered;
