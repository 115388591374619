import React, {useState, useEffect} from "react";
import styled from "styled-components";
import firebase from "firebase";
import UserComponent from "./UserVerification/UserComponent";
import {MDBDataTableV5} from "mdbreact";
import {Button} from "react-bootstrap";
import Modal from 'react-modal'
import {getB2BUsers, updateB2BUser} from "../helpers/firebase/firestore";

const Container = styled.div`
  h1 {
    color: #000;
  }
`;

const TABLE_COLUMN_STRUCTURE = [{
    label: 'Shop Name', field: 'shopName', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Category',
    },
}, {
    label: 'Owner Name', field: 'ownerName', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Description',
    },
}, {
    label: 'Shop Address', field: 'shopAddress', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Description',
    },
}, {
    label: 'Mobile', field: 'phone', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Description',
    },
}, {
    label: 'Joined', field: 'joined', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Description',
    },
}, {
    label: 'Action', field: 'action', attributes: {
        'aria-controls': 'DataTable', 'aria-label': 'Action',
    },
},]

const UserVerification = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [newUsers, setNewUsers] = useState({daily: 0, monthly: 0, allTime: 0})
    const [verifying,setVerifying]=useState(false);
    const MoreActionButton = ({user}) => <Button variant={'secondary'}
                                                 onClick={() => setSelectedUser(user)}>More</Button>

    useEffect(() => {
        getB2BUsers().then(({data}) => {
            if (!data) return;
            data.users.forEach((user, index) => {
                if(!user) return;
                user.index=index;
                user.joined=new Date(user.createdAt).toDateString();
                user.action=<MoreActionButton user={user}/>
                setUsers(o=>[...o,user])
            });
            setNewUsers({daily: data.daily, monthly: data.monthly, allTime: users.length})
        })
    }, []);

    const UpdateUserStatus = (index, profileStatus = true) => {
        setVerifying(true)
        const uid = users[index].uid;
        updateB2BUser(uid, {profileStatus})
            .then(() => setUsers(oldUsers => {
                oldUsers[index].profileStatus = profileStatus;
                return [...oldUsers];
            })).then(() => {
            setSelectedUser(o => ({...o, profileStatus}))
        }).finally(()=>{
            setVerifying(false);
        })

    };
    const UpdateUserDetails = (index, details) => {
        setUsers((oldUsers) => {
            oldUsers[index]={...oldUsers[index],details};
            console.log(oldUsers[index])
            return [...oldUsers];
        })
        setSelectedUser(o => ({...o, details}))

    };
    return (<Container>
        <Modal isOpen={selectedUser !== null} onRequestClose={() => setSelectedUser(null)}>
            <div>
                <div className="w-100 d-flex justify-content-end">
                    <button onClick={() => setSelectedUser(null)} className="btn btn-danger btn-sm">Close</button>
                </div>
                {selectedUser && <UserComponent
                    VerifyUser={UpdateUserStatus}
                    UpdateUserDetails={UpdateUserDetails}
                    key={selectedUser?.uid}
                    userDetails={selectedUser}
                    index={selectedUser.index}
                    verifying={verifying}
                    {...selectedUser}
                />}
            </div>
        </Modal>
        <h3 className="title is-2 has-text-black px-2 ">Users</h3>
        <div className={'d-flex gap-3 mx-3'}>
            <h4>New Users Today :<b> {newUsers.daily}</b></h4>
            <h4>New Users in last 30 days : <b>{newUsers.monthly}</b></h4>
            <h4>Total Users : <b>{newUsers.allTime}</b></h4>
        </div>
        {users.length === 0 ? (<h3 className="title is-2">Loading</h3>) :
            <MDBDataTableV5 fixed={true} entriesOptions={[5, 20, 25,]}
                            entries={20} pagesAmount={4} scrollX hover data={{
                columns: TABLE_COLUMN_STRUCTURE, rows: users
            }}/>}
    </Container>);
};
export default UserVerification;
