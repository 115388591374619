import React, { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Admin/Dashboard';
import App from './App';
import ReactNotification from "react-notifications-component";
import Categories from './Admin/Category/Categories';
import AddCategory from './Admin/Category/AddCategory';
import Products from './Admin/Products/Products';
import AddProduct from './Admin/Products/AddProduct';
import Orders from './Admin/Orders/Orders';
import OrderDetails from './components/OrderDetails';
import Users from './Admin/User/Users';
import Analytics from './Admin/Analytics/Analytics';
import PushNotifications from './components/PushNotifications'
import firebase from "firebase";
import Sidebar from './Admin/Sidebar';
import history from './Admin/StateManager/history';
import {RouteStore} from './Admin/StateManager/StateManager'
import * as Constants from './Admin/StateManager/Constants'
import ShippingTypes from './Admin/Shipping/ShippingTypes';
import EditProduct from './Admin/Products/EditProduct';
import Units from './Admin/Unit/Units';
import Coupons from './Admin/Coupons/Coupons';
import Banners from './Admin/Banners/Banners';
import DeliveryBoys from './Admin/User/DeliveryBoys';
import UserComponent from "./components/UserVerification/UserComponent";
import UserVerification from "./components/UserVerification";
import EditProducts from "./components/EditProducts";
import UploadVegetables from "./components/UploadVegetables";
import SetDeliveryRate from "./components/OrderDetails/SetDeliveryRate";
import AboutUs from './Admin/About/AboutUs';
import AboutPage from './Admin/About/AboutPage';
import CommonSettings from './Admin/Settings/settings';
import DeletePage from './Admin/About/DeletePage';
function AppRoutes() {
  const [LoggedIn, _setLoggedIn] = React.useState(null);
  const [isAboutPage, setisAboutPage] = useState(null)
  const unlisten = history.listen((location, action) => {
    Constants.setPageTitle();
    console.log(action, location.pathname, location.state);
  });
  function RequireAuth({ children, redirectTo }) {
    if(LoggedIn===null) return children
    return LoggedIn ? children : <Navigate to={redirectTo} />;
  }
  React.useState(() => {
    unlisten();
    try {
    } catch { }
  }, []);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem("uid", user.uid);
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            localStorage.setItem("token", token);
            _setLoggedIn(true);
          });
      } else {
        _setLoggedIn(false);
      }
      console.log(window.location.pathname);
      setisAboutPage(window.location.pathname === '/about-us')
      
    });
  }, []);

  return (
    <div className="App">
      <ReactNotification />
      <Sidebar isLoggedin={LoggedIn && !isAboutPage} title={'Dashboard'} />
      <Routes history={history}>
        <Route path="/" element={<App />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/delete-req-form" element={<DeletePage />} />
        <Route path="common-settings" element={<RequireAuth redirectTo="/"><CommonSettings /></RequireAuth>} />
        <Route path="about-us-page" element={<RequireAuth redirectTo="/"><AboutUs /></RequireAuth>} />
        <Route path="categories" element={<RequireAuth redirectTo="/"><Categories /></RequireAuth>} />
        <Route path="delivery-rates" element={<RequireAuth redirectTo="/"><SetDeliveryRate /></RequireAuth>} />
        <Route path="add-category" element={<RequireAuth redirectTo="/"><AddCategory /></RequireAuth>} />
        <Route path="products" element={<RequireAuth redirectTo="/"><Products /></RequireAuth>} />
        <Route path="add-product" element={<RequireAuth redirectTo="/"><AddProduct /></RequireAuth>} />
        <Route path="orders" element={<RequireAuth redirectTo="/"><Orders /></RequireAuth>} />
        <Route path="orderdetails" element={<RequireAuth redirectTo="/"><OrderDetails /></RequireAuth>} />
        <Route path="users-b2b" element={<RequireAuth redirectTo="/"><UserVerification /></RequireAuth>} />
        <Route path="push-notif" element={<RequireAuth redirectTo="/"><PushNotifications /></RequireAuth>} />
        <Route path="categories-b2b" element={<RequireAuth redirectTo="/"><EditProducts /></RequireAuth>} />
        <Route path="upload-vegetables" element={<RequireAuth redirectTo="/"><UploadVegetables /></RequireAuth>} />
        <Route path="orders-b2b" element={<RequireAuth redirectTo="/"><OrderDetails /></RequireAuth>} />
        <Route path="analytics" element={<RequireAuth redirectTo="/"><Analytics /></RequireAuth>} />
        <Route path="shippingTypes" element={<RequireAuth redirectTo="/"><ShippingTypes /></RequireAuth>} />
        <Route path="units" element={<RequireAuth redirectTo="/"><Units /></RequireAuth>} />
        <Route path="coupons" element={<RequireAuth redirectTo="/"><Coupons /></RequireAuth>} />
        <Route path="banners" element={<RequireAuth redirectTo="/"><Banners /></RequireAuth>} />
        <Route path="deliveryBoys" element={<RequireAuth redirectTo="/"><DeliveryBoys /></RequireAuth>} />
        <Route path="edit-product" element={<RequireAuth redirectTo="/"><EditProduct /></RequireAuth>} />
      </Routes>
    </div>

  )
}

export default AppRoutes
