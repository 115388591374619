import React from "react";
import styled from "styled-components";
import Switch from "react-switch";
import firebase from "firebase";
import { store } from 'react-notifications-component';


const Container = styled.div`
  h1 {
    color: #000;
  }
`;

const ProductCard = (props) => {
  const [data, _setData] = React.useState({});
  const [productNewprice, setProductNewprice] = React.useState(0)
  const [penddingProductArr, setPenddingProductArr] = React.useState(props.pendngOrder)
  const [savemode, setSavemode] = React.useState(0);
  // console.log(props.pendngOrder[1]["userid"],props.pendngOrder[1]["orders"]);


  React.useEffect(() => {
    // console.log("dataObj ",dataObj);
    var dataObj = props.productCardData;
    let prevPric = props.productCardData.prevPrice||props.productCardData.price;
    dataObj.prevPrice = prevPric;
    setProductNewprice(prevPric);
    _setData(dataObj);
  }, [props]);

  const updatePendingOrders = ()=>{
    setTimeout(() => {
      data.prevPrice =  data.price;
      _setData(data);
    }, 200);
    // console.log(penddingProductArr);
    for (var ind in penddingProductArr) {
      // console.log(penddingProductArr[ind]["userid"],penddingProductArr[ind]["orders"]);
      for (var orderid in penddingProductArr[ind]["orders"]) {
        let orderDet = penddingProductArr[ind]["orders"][orderid];
        // console.log(orderDet.status,orderDet.product);
        if (orderDet.status === "processed") {
          let isUpdated = false;
          let totalPrice = 0;
          for (var prodIndex in orderDet.product) {
            // console.log(orderDet.product[prodIndex].productName,orderDet.product[prodIndex]);
            // console.log(orderDet.product[prodIndex].productName,orderDet.product[prodIndex]);
            if (orderDet.product[prodIndex].productName === props.productCardData.itemDatabase) {
              console.log("Match fond");
              orderDet.product[prodIndex].price = productNewprice;
              isUpdated = true;
              // console.log(penddingProductArr[ind].userid, ind, orderid, orderDet);
            }
            let proPrice = orderDet.product[prodIndex].price * orderDet.product[prodIndex].quantity.quantity;
            totalPrice = totalPrice + proPrice;
          }
          orderDet.price = totalPrice>0?totalPrice:orderDet.price;
          if(isUpdated){
            // console.log(orderDet.product);
            // console.log(orderid);
            // console.log(orderDet);
            let phone = penddingProductArr[ind].userid;
              firebase
                .database()
                .ref(`order/${phone}/${orderid}`)
                .update({price:totalPrice,product:orderDet.product})
                .then(() => {
                  console.log("updated");
                });
          }
          
        }
      }
    }
  }
  const Save = (adjmode) => {
    // console.log(penddingProductArr);
    // console.log(props.selectedCategory,props.productCardData.itemDatabase);
    console.log(adjmode,adjmode===1);
    firebase
      .database()
      .ref(`vegetables/${props.selectedCategory}/${props.productCardData.itemDatabase}`)
      .update({ ...data })
      .then(() => {
        if(adjmode===1){
          updatePendingOrders();
        } 
        store.addNotification({
          title: "Notification",
          message: "Changes saved",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true,
          },
        });
      });
  };

  return (
    <Container className="card mx-2 px-3 py-3">
      <div className="columns is-multiline ">
        <div className="column is-12">
          <h3 className="title is-2 ">{data.productName}</h3>
        </div>
        <div className="column is-6 my-0 py-0">
          {(data.prevPrice!=data.price) &&(<span>Previous Price {data.prevPrice||data.price}</span>)}
          <h3 className="subtitle is-5 mb-2">Price</h3>
          <input
            onChange={(e) => {
              _setData({ ...data, price: e.target.value });
            }}
            value={data.price}
            className="input"
          />
          <h3 className="subtitle is-5 mb-2">Adjust</h3>
          <input
            onChange={(e) => {
              setProductNewprice(e.target.value);
              _setData({ ...data, price: e.target.value });
            }} 
            value={productNewprice}
            className="input mt-1"
          />
        </div>
        <div className="column is-6 my-0 py-0">
          <h3 className="subtitle is-5 mb-2">Hide Product</h3>
          <Switch
            onChange={() => {
              _setData({ ...data, hide: !data.hide });
            }}
            checked={data.hide === true ? true : false}
          />
        </div>
        <div className="column is-6 my-2 ">
          <h3 className="subtitle is-5 mb-2">Out Of Stock</h3>
          <Switch
            onChange={() => {
              _setData({ ...data, outOfStock: !data.outOfStock });
            }}
            checked={data.outOfStock === true ? true : false}
          />
        </div>
        <div className="column is-6 " style={{justifyContent:'space-evenly', display:"flex"}}>
          <button
            onClick={()=>{ Save(0); }}
            style={{ width: "45%" }}
            className="button is-link mt-5"
          >
            Save
          </button>
          <button
            onClick={()=>{ Save(1); }}
            style={{ width: "45%" }}
            className="button is-link mt-5"
          >
            Adjust
          </button>
        </div>
      </div>
    </Container>
  );
};
export default ProductCard;
