import history from "./history";
import { RouteStore } from "./StateManager";

export const setPageTitle = (refferPath,byPath=true) => {
    let pathname = refferPath||window.location.pathname;
    console.log(pathname);
    
    let routeInfos = [];
    routeInfos.push({ pageTitle: "Edit About Us", path:"/about-us-page" ,activeRoute: "/about-us-page", currentFun: null });
    routeInfos.push({ pageTitle: "Settings", path:"/common-settings" ,activeRoute: "/common-settings", currentFun: null });
    routeInfos.push({ pageTitle: "Home", path:"/" ,activeRoute: "/", currentFun: null });
    routeInfos.push({ pageTitle: "Dashboard", path:"/dashboard" ,activeRoute: "/dashboard", currentFun: null });
    routeInfos.push({ pageTitle: "Categories", path:"/categories" ,activeRoute: "/categories", currentFun: null });
    routeInfos.push({ pageTitle: "Banners", path:"/banners" ,activeRoute: "/banners", currentFun: null });
    routeInfos.push({ pageTitle: "Delivery Boys", path:"/deliveryBoys" ,activeRoute: "/deliveryBoys", currentFun: null });
    routeInfos.push({ pageTitle: "Add Category", path:"/add-category" ,activeRoute: "/categories", currentFun: null });
    routeInfos.push({ pageTitle: "Products", path:"/products" ,activeRoute: "/products", currentFun: function () { return '/add-product'; } });
    routeInfos.push({ pageTitle: "Add Product", path:"/add-product" ,activeRoute: "/products", currentFun: null });
    routeInfos.push({ pageTitle: "Edit Product", path:"/edit-product" ,activeRoute: "/products", currentFun: null });
    routeInfos.push({ pageTitle: "Orders", path:"/orders" ,activeRoute: "/orders", currentFun: null });
    routeInfos.push({ pageTitle: "Order Details", path:"/orderdetails" ,activeRoute: "/orders", currentFun: null });
    routeInfos.push({ pageTitle: "Analytics", path:"/analytics" ,activeRoute: "/analytics", currentFun: null });
    routeInfos.push({ pageTitle: "Users", path:"/users" ,activeRoute: "/users", currentFun: null });
    routeInfos.push({ pageTitle: "Shipping Types", path:"/shippingTypes" ,activeRoute: "/shippingTypes", currentFun: null });
    let currentRoute = null;
    if(byPath){
        currentRoute = routeInfos.find(element => element.path === pathname);
    }else{
        currentRoute = routeInfos.find(element => element.activeRoute === pathname);
    }
    // console.log(currentRoute);
    if(currentRoute){
        RouteStore.update(s => {
            s.pageTitle = currentRoute.pageTitle;
            s.activeRoute = currentRoute.activeRoute;
            s.path = currentRoute.path;
            s.currentFun = currentRoute.currentFun
        });
    }
   
}
export const selectedProduct={prod:{},prodID:"",catID:"", categories:{},units:{},coupons:{}}
export const selectedImgIndex = {pos:-1}
export const updatedOrder = {pos:-1,item:{}}
export const orderList = {orders:{}}
