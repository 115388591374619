import React, { useState, useEffect } from "react";
import  './about.css' 
import firebase from "firebase";


function AboutPage() {
    const [showMenu, setShowMenu] = useState("none");
      const [isLayoutReady, setIsLayoutReady] = useState(false);
      const imagepath = "aboutus/images";
    
      useEffect(() => {
        document.body.style.backgroundColor = "#c9e165";
        setIsLayoutReady(true);
        loadExistingData();
        // isMobile
        if(window.innerWidth > 600){
          setShowMenu("flex");
        }
        return () => setIsLayoutReady(false);
      }, []);
      const [aboutUsContent, setAboutUsContent] = useState({
        banner: {
          image: "",
          content: "",
        },
        applinks: {
          ios: "",
          android: "",
        },
        whyus: [
          {
            image: "",
            content: "",
          },
          {
            image: "",
            content: "",
          },
          {
            image: "",
            content: "",
          },
          {
            image: "",
            content: "",
          },
        ],
        aboutus: {
          image: "",
          content: "",
        },
        vision: {
          image: "",
          content: "",
        },
        recognition: {
          image: "",
          content: "",
        },
        founder: {
          image: "",
          content: "",
        },
        contact: {
          name: "",
          adderess: "",
          address: "",
          email: "",
          phone: "",
        },
        social: {
          facebook: "",
          instagram: "",
          twitter: "",
        },
      });
      const loadExistingData = async () => {
        firebase
          .database()
          .ref("pages/aboutus")
          .once(`value`, (snap) => {
            const data = snap.val();
            // console.log(JSON.stringify(data));
            setAboutUsContent(data);
            // editorInstanceRef.current.setData(data?.content || "");
          });
      };

    const toggleMenu = () => {
    //   const nav = document.querySelector(".nav");
    //   nav.style.display = nav.style.display === "flex" ? "none" : "flex";
        setShowMenu(showMenu === "flex" ? "none" : "flex");
    }
  return (
    <div>
      <div className="header">
        <div className="logo">
          <img src="/assets/Logo.png" alt="" />
        </div>
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <div className="nav" style={{ display: showMenu }}>
          <a href="#">Weather</a>
          <a href="#">Community</a>
          <a href="#about-us">About Us</a>
          <a href="#">Contact Us</a>
        </div>
      </div>
      <div className="top-content">
        <div className="phone-image">
          <img src={aboutUsContent.banner.image} alt="" />
        </div>
        <div className="quote-section" style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <div className="quote">
            {aboutUsContent.banner.content}
          </div>
          <img src="/assets/b2b_logo.jpeg" style={{
            width: "150px",
            marginTop: "6%"
          }} alt="" />
          <div className="google-play">
            <div>Wholesale & Retail</div>
            <a href={aboutUsContent.applinks.android||"#"} target="_blank" rel="noopener noreferrer">
              <img src="/assets/google-play.png" alt="" />
            </a>
          </div>
        </div>
      </div>
        <div className="bborder" ></div>
      <div className="content">
        <h2>Why Us ?</h2>
        <div className="content-image  make-center">
          <img src={aboutUsContent.whyus[0].image} alt="" />
        </div>
        <p className="content-text">
        {aboutUsContent.whyus[0].content}
        </p>
        <div className="content-image">
          <img src={aboutUsContent.whyus[1].image} alt="" />
        </div>
        <p className="content-text">
        {aboutUsContent.whyus[1].content}
        </p>
        <div className="content-image">
          <img src={aboutUsContent.whyus[2].image} alt="" />
        </div>
        <p className="content-text">
        {aboutUsContent.whyus[2].content}
        </p>
        <div className="content-image">
          <img src={aboutUsContent.whyus[3].image} alt="" />
        </div>
        <p className="content-text">
        {aboutUsContent.whyus[3].content}
        </p>
      </div>
      <div className="bborder" ></div>
      <div className="content" id="about-us">
        <h2>About us</h2>

        <p className="content-text">
        {aboutUsContent.aboutus.content}
        </p>
        <br />
        <br />
        <br />
        <h2>Vision</h2>
        <p className="content-text">
        {aboutUsContent.vision.content}
        </p>
        <br />
        <br />
        <br />
        <div className="content-image">
          <img src={aboutUsContent.recognition.image} alt="" />
        </div>
        <p className="content-text">
        {aboutUsContent.recognition.content}
        </p>
      </div>
      <footer className="footer" >
        <div className="footer-content">
          <div className="about-founder">
            <h2>About The Founder</h2>
            {(aboutUsContent.founder.content||"").split("\n").map((line, index) => (
              <p key={index}>
                {line}
                </p>
            ))}
          </div>
          <br />
          <br />
          <div className="footer-founder-separator">
            <div className="footer-info">
              <div className="logo">
                <img src="/assets/Logo.png" alt="" />
              </div>
              <p>{aboutUsContent.contact.name},</p>
              {(aboutUsContent.contact.address||"").split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </div>
            <div className="social-media">
              <h2>Connect us on</h2>
              <a href={aboutUsContent.social.linkedin}>
                <img src="/assets/social/linkedin-logo.png" alt="" />
              </a>
              <a href={aboutUsContent.social.instagram}>
                <img src="/assets/social/instagram.png" alt="" />
              </a>
              <a href={aboutUsContent.social.facebook}>
                <img src="/assets/social/facebook.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default AboutPage
